.footer {
    flex-shrink: 0;
    text-align: center;
    background-color: #1a1919;
    padding: 2em 20em;
    color: white;
    vertical-align: baseline;
    position: absolute;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: left;
  }

  .column-2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
    align-items: left;
  }
  
  
  .social-media-ul {
    display: block;
    align-items: center;
    list-style-type: none;
    overflow: hidden;
  }

  .social-media-ul a {
    display: block;
    font-family: cheap-pine-sans, sans-serif;
    font-size: 2em;
    font-weight: 400;
    font-style: normal;
    color: #EEB02E;
    text-align: left;
    padding: 6px 0;
    text-decoration: none;
  }

  .info-ul li {
    display: block;
    font-family: cheap-pine-sans, sans-serif;
    font-size: 1.85em;
    font-weight: 400;
    font-style: normal;
    color: #A7A69C;
    text-align: left;
    padding: 2px 0;
    text-decoration: none;
  }

  .info-ul li a {
    display: block;
    font-family: cheap-pine-sans, sans-serif;
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    color: #A7A69C;
    text-align: left;
    padding: 2px 0;
    text-decoration: none;
  }
  
  .address{
    width: max-content;
    padding-bottom: 1px !important;
    border-bottom: 1px solid #605f5a;
  }

  .alpha-dates {
    color: #605f5a;
  }

  .copy-right-li {
    margin-top: 0.5em;
  }

  .copy-right-span {
    font-size: 0.6em;
    color: #605f5a;
  }

  .info-ul {
    list-style-type: none;
    overflow: hidden;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }

  li > a > img {
    height: 1em;
    width: 1em;
    display: inline-block;
    vertical-align: middle;
    float: left;
  }

  li a:hover {
    color: #c49327;
  }

  @media screen and (max-width: 1200px) {
    .social-media-ul a {
      font-size: 1.9em;
    }

    .footer {
      flex-shrink: 0;
      text-align: center;
      background-color: #1a1919;
      padding: 1em 0;
      color: white;
      vertical-align: baseline;
      width: 100%;
      position: absolute;
    }

    .column {
      width: 149%;
    }
  }

  /* @media screen and (min-width: 1200px) and (max-width: 1200px) {
    .column {
      width: 149%;
    }
  } */