body {
    background-color: #f1f0e1;
  }
  
  .content-section-awards {
    overflow: hidden;
    margin: 0 auto;
    background: url("/img/1-006.jpg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .content-block {
    background-color: #fff;
    position: relative;
    padding: 0.5rem 4rem;
  }
  
  .container--medium {
    margin: 0 auto;
  }

  .awards-ul {
    padding-inline-start: 0px !important;
  }

  /* Responsive Menu */
  .award-lists > div {
    display: inline-block;
    width: 100%;
  }
  .award-lists > div:not(:last-child) {
    margin-bottom: 5em;
  }
  .award-lists > div h2 {
    text-align: center;
    font-family: cheap-pine-sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1041666667;
    font-size: 3.5em;
    color: #c23b22;
    margin-bottom: 2.125rem;
  }

  .award-lists > div ul li {
    display: inline-block;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* background-image: url("/img/hr-dot-gray.svg"); */
    -webkit-background-size: 10px 10px;
    background-size: 10px;
    background-repeat: repeat-x;
    background-position: left bottom 0.25em;
  }
  .award-lists > div ul li:not(:last-child) {
    margin-bottom: 1em;
  }
  .award-lists > div ul li span {
    font-family: cheap-pine-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.125;
    font-size: 1.5em;
    color: #605f5a;
    background-color: #fff;
    text-transform: uppercase;
  }
  .award-lists > div ul li span:first-child {
    padding-right: 0.5833333333rem;
  }
  .award-lists > div ul li span:nth-child(2) {
    padding-left: 0.5rem;
    /* padding-right: 0.5rem; */
    float: right;
    text-align: right;
  }

  .menu-lists > div ul li span:last-child {
    padding-left: 0.5rem;
  }
  .menu-lists > div ul li span small {
    color: #a7a69c;
    font-size: 0.8rem;
  }
  .menu-lists > div .combined {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .menu-lists > div .combined ul {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }
  .menu-lists > div .combined ul:first-child {
    -webkit-box-flex: 3;
    -webkit-flex-grow: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
  }
  .menu-lists > div .combined ul:first-child li {
    background-image: none;
  }
  .menu-lists > div .combined ul:first-child li span {
    padding: 0;
  }
  
  .content-section-awards {
    padding: 1rem 0 3.5em;
    overflow: hidden;
  }

  @media screen and (min-width: 480px) {
    .menu-lists > div h2 {
      font-size: 3em;
    }
  }
  @media screen and (min-width: 700px) {
    .menu-lists > div h2 {
      font-size: 3em;
    }
  }
  @media screen and (min-width: 700px) {
    .menu-lists {
      padding: 0 -webkit-calc((8.3333333333vw * 0.5) - 2rem);
      padding: 0 calc((8.3333333333vw * 0.5) - 2rem);
    }
  }
  @media screen and (min-width: 1000px) {
    .menu-lists {
      padding: 0 2rem;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: -webkit-calc((8.3333333333vw * 1) - 2rem);
      -moz-column-gap: calc((8.3333333333vw * 1) - 2rem);
      column-gap: calc((8.3333333333vw * 1) - 2rem);
    }
  }
  @media screen and (min-width: 1280px) {
    .menu-lists {
      padding: 0 -webkit-calc((8.3333333333vw * 0.7) - 2rem);
      padding: 0 calc((8.3333333333vw * 0.7) - 2rem);
      -webkit-column-gap: -webkit-calc((8.3333333333vw * 1.1875) - 2rem);
      -moz-column-gap: calc((8.3333333333vw * 1.1875) - 2rem);
      column-gap: calc((8.3333333333vw * 1.1875) - 2rem);
    }
  }

  @media screen and (min-width: 480px) {
    .content-section-awards {
      padding: 2.5em 0 4em;
    }
  }
  @media screen and (min-width: 700px) {
    .content-section-awards {
      padding: 3.5em 0 6em;
    }
  }
  @media screen and (min-width: 1000px) {
    .content-section-awards {
      padding: 5em 0 11em;
    }
    .navbar-nav {
      display: flex;
      flex-direction: row;
      margin-right: 100px;
    }
  }
  .content-section-awards .container > section:before {
    content: "";
    display: block;
    background-image: url("/img/hr-dot-gray.svg");
    -webkit-background-size: 10px 10px;
    background-size: 10px;
    background-repeat: repeat-x;
    background-position: left top;
    height: 1.5em;
    padding-bottom: 2em;
  }
  @media screen and (min-width: 700px) {
    .content-section-awards .container > section:before {
      padding-bottom: 4.25em;
    }
  }
  .content-section-awards .container > section:not(:first-of-type) {
    padding-top: 4em;
  }
  @media screen and (min-width: 700px) {
    .content-section-awards .container > section:not(:first-of-type) {
      padding-top: 6em;
    }
  }
  @media screen and (max-width: 699px) {
    .content-section-awards .container > section:not(:first-of-type):before {
      padding-bottom: 3em;
    }

    .award-lists > div h2 {
      margin: 0.5em;
      font-size: 3.5em;
    }

    .award-lists > div ul li span {
      line-height: 1.125;
      font-size: 1em;
      color: #605f5a;
      background-color: #fff;
      text-transform: uppercase;
    }

    .content-block {
      padding: 0.5rem 0.5rem;
    }
  }
  .site-header + .content-section-awards {
    overflow: hidden;
  }
  @media screen and (min-width: 480px) {
    .site-header + .content-section-awards {
      padding-top: 1.25em;
    }
  }
  @media screen and (min-width: 700px) {
    .site-header + .content-section-awards {
      padding-top: 1.75em;
    }
  }
  @media screen and (min-width: 1000px) {
    .site-header + .content-section-awards {
      padding-top: 2em;
    }
  }
  .site-header + .content-section-awards[style^="background-image:"] {
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 2em;
  }
  @media screen and (min-width: 700px) {
    .site-header + .content-section-awards[style^="background-image:"] {
      padding-top: -webkit-calc((8.3333333333vw * 1.2) - 2rem);
      padding-top: calc((8.3333333333vw * 1.2) - 2rem);
    }
  }
  .hero + .content-section-awards .container > section:first-of-type:before {
    display: none;
  }
  
  @media screen and (min-width: 700px) {
    .container {
      width: -webkit-calc(100% - 2rem);
      width: calc(100% - 2rem);
    }
  }
  @media screen and (max-width: 699px) {
    .container .container {
      margin: initial;
      width: initial;
    }
  }
  @media screen and (min-width: 700px) {
    .container--large {
      width: -webkit-calc((8.3333333333vw * 11) - 2rem);
      width: calc((8.3333333333vw * 11) - 2rem);
    }
  }
  @media screen and (min-width: 1280px) {
    .container--large {
      width: -webkit-calc((8.3333333333vw * 10) - 2rem);
      width: calc((8.3333333333vw * 10) - 2rem);
    }
  }
  @media screen and (min-width: 700px) {
    .container--medium {
      width: -webkit-calc((8.3333333333vw * 9) - 2rem);
      width: calc((8.3333333333vw * 9) - 2rem);
    }
  }
  @media screen and (min-width: 1280px) {
    .container--medium {
      width: -webkit-calc((8.3333333333vw * 8) - 2rem);
      width: calc((8.3333333333vw * 8) - 2rem);
    }
  }
  @media screen and (max-width: 699px) {
    .container--widened {
      width: -webkit-calc(100% - 1rem);
      width: calc(100% - 1rem);
    }
  }
  @media screen and (max-width: 699px) {
    .container--padded {
      padding: 0 0.5rem;
    }
  }
  /* End of Responsive Menu */
  
  