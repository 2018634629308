.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: cheap-pine-sans, sans-serif;
    font-size: 2em;
    font-weight: 300;
    font-style: normal;
    color: #605f5a;
    height: 3.5em;
    overflow: hidden;
    transition: all 0.3s ease;
    flex-wrap: wrap;
    width: 100%;
}

.activeRoute {
    border-top-color: #EEB02E;
    color: #605f5a !important;
}

.navbar div{
    float: left;
    clear: none; 
}


@media screen and (max-width: 850px) {
    .navbar-brand {
        flex: 1%;
        text-align: left;
        padding-left: 50px;
    }
    .navbar {
        flex-wrap: wrap;
        height: 4.25em;
        margin-bottom: 0.5em;
    }
    .navbar-menu {
        margin: 0 3em;
    }

    .navlink {
        text-decoration: none;
        font-size: 28px;
        color: #605f5a;
        border-top: 0.45rem solid transparent;
        padding: 0.4rem 1.05rem;
    }

    .chownow-order-online {
        text-decoration: none;
        font-size: 28px;
        border-top: 0.45rem solid transparent;
        padding: 0.4rem 1.05rem;
    }
}

@media screen and (min-width: 850px) {
    .navbar-brand {
        flex: 1%;
        text-align: right;
    }

    .navbar-menu {
        flex: 30%;
        justify-content: left;
        text-align: left;
        margin-left: 3em;
    }

    .navlink {
        text-decoration: none;
        margin: 2.75em 0.5em;
        font-size: 30px;
        color: #605f5a;
        border-top: 0.6rem solid transparent;
        padding: 0.66667rem 0.5rem;
    }

    .chownow-order-online {
        text-decoration: none;
        margin: 2.75em 0.5em;
        font-size: 30px;
        border-top: 0.6rem solid transparent;
        padding: 0.66667rem 0.5rem;
    }
}

.navlink:hover {
    color: #1a1919;
}

.chownow-order-online:hover {
    color: #bf2626;
}